import { MenuItem as MuMenuItem, withStyles } from '@material-ui/core';

const MenuItem = withStyles((theme) => ({
    root: {
        padding: "10px 20px",
        color: "#616161",
        '&:hover': {
            color: "#202124",
            boxShadow: '0px 0px 0px rgba(46, 155, 255, 0.5)',
            transition: 'all 0.3s',
        }
    }
}))(MuMenuItem);

export default MenuItem;