import clsx from "clsx";
import { createUseStyles } from "react-jss";
import WhatsAppIcon from 'assets/web/whatsapp.svg';
import TwitterIcon from 'assets/web/twitter.svg';
import LinkedInIcon from 'assets/web/linkedin.svg';
import FacebookIcon from 'assets/web/facebook.svg';
import config from "config";
import { handleLinkClick, WHATSAPP_LINK_EVENT } from "utils/events";

const useStyles = createUseStyles({
    footer: {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #d0d0d0',
    },
    footerWrapper: {
        width: '-webkit-fill-available',
    },
    footerWrapperMoz: {
        width: '-moz-available',
    },
    title: {},
    social: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        margin: "0 10px",
        display: "flex",
        alignItems: "center",
    },
    socialFollow: {
        display: "flex",
        flexDirection: "row",
    },
    '@media screen and (max-width: 760px)': {
        footer: {
            height: '200px',
        },
        footerWrapper: {
            flexDirection: "column-reverse",
        },
        title: {
            marginBottom: "40px",
        },
        social: {
            flexDirection: "column",
            margin: "20px 0",
        },
        icon: {
            margin: "20px 10px",
        }
    }

})

function Footer() {
    const classes = useStyles();
    const date = new Date();

    return (
        <div className="max-width sectionGap">
            <div className={classes.footer}>
                <div className={clsx("space-between", classes.footerWrapper, classes.footerWrapperMoz)}>
                    <div className={classes.title}>
                        © {date.getFullYear()} StayQrious. All rights reserved
                    </div>
                    <div className={classes.social}>
                        <a
                            id="whatsapp-footer"
                            className="text-decoration-none"
                            onClick={(e) => handleLinkClick(WHATSAPP_LINK_EVENT, e, config.WHATSAPP_LINK)}
                            href={config.WHATSAPP_LINK}
                        >
                            <div className={classes.icon}>
                                <WhatsAppIcon />{config.WHATSAPP_NUMBER}
                            </div>
                        </a>
                        <div className={classes.socialFollow}>
                            {/* <div className={classes.icon}>
                                <TwitterIcon />
                            </div> */}
                            <a href={config.LINKEDIN_LINK}>
                                <div className={classes.icon}>
                                    <LinkedInIcon />
                                </div>
                            </a>
                            <a href={config.FACEBOOK_LINK}>
                                <div className={classes.icon}>
                                    <FacebookIcon />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;