import { Menu as MuMenu, MenuProps } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const Menu = withStyles(() => ({
    paper: {
        borderRadius: "15px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
}))((props: MenuProps) => (
    <MuMenu
        elevation={props.elevation || 0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}

        {...props}
    />
));

export default Menu;