import {
    handleApplyNowEvent,
    handleDetailFormDismissed,
    handleDetailFormSubmitted,
    handleDownloadCurriculumEvent,
    handleHomeButtonClick,
    handleMasterClassClickEvent,
    handleMPEnrollNowClick,
    handleMPWhatsAppClick,
    handleVideoPlayEvent,
    handleYouTubeClick
} from "./mixpanelEvents";

export const ENROL_EVENT = 'enrol';
export const WHATSAPP_LINK_EVENT = 'whatsapp_link';
export const FORM_SUBMIT_EVENT = 'form_submit';
export const FORM_SUBMIT_DISMISSED = 'form_submit_dismissed';

export const HOME_BUTTON_EVENT = 'home_button';
export const YOUTUBE_BUTTON_EVENT = 'youtube_button';
export const VIDEO_PLAY_EVENT = 'video_play';
export const APPLY_NOW_EVENT = 'apply_now';
export const DOWNLOAD_CURRICULUM_EVENT = 'download_curriculum';
export const MASTERCLASS_CLICK_EVENT = 'masterclass_click';

export const handleLinkClick = (
    type: string,
    e?: any,
    link?: any,
    target?: string,
) => {

    if (e) {
        e.preventDefault();
    }

    switch (type) {
        case ENROL_EVENT:
            handleMPEnrollNowClick(e.currentTarget.id);
            break;
        case WHATSAPP_LINK_EVENT:
            handleMPWhatsAppClick(e.currentTarget.id);
            break;
        case FORM_SUBMIT_EVENT:
            handleDetailFormSubmitted();
            break;
        case FORM_SUBMIT_DISMISSED:
            handleDetailFormDismissed();
            break;
        case HOME_BUTTON_EVENT:
            handleHomeButtonClick();
            break;
        case YOUTUBE_BUTTON_EVENT:
            handleYouTubeClick(e.currentTarget.id);
            break;
        case VIDEO_PLAY_EVENT:
            handleVideoPlayEvent(e.currentTarget.id);
            break;
        case DOWNLOAD_CURRICULUM_EVENT:
            handleDownloadCurriculumEvent(e.currentTarget.id);
            break;
        case MASTERCLASS_CLICK_EVENT:
            handleMasterClassClickEvent(e.currentTarget.id);
            break;
        default:
            break
    }
    setTimeout(() => {
        if (typeof window !== undefined) {
            if (link) {
                window.open(link, target);
                // window.location = link;
            } else {
                window.open(e.target.href, target);
            }
        }
    }, 100);
    return
}