import Link from 'next/link';
import { useState } from 'react';
import styles from 'styles/components/header.module.scss';
import WhatsAppIcon from 'assets/web/whatsapp.svg';
import MenuItem from 'components/styledComponents/MenuItem';
import Menu from 'components/styledComponents/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Button from 'components/generics/Button';
import { withCookies } from 'react-cookie';
import { useEffect } from 'react';
import {
  handleLinkClick,
  HOME_BUTTON_EVENT,
  WHATSAPP_LINK_EVENT,
  YOUTUBE_BUTTON_EVENT
} from 'utils/events';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import YouTube from 'assets/web/MUYoutube.svg';
import WhatsApp from 'assets/web/MuWhatsapp.svg';
import config from 'config';
import { useRouter } from 'next/router';

const useStyles = createUseStyles({
  headerMaxWidth: {
    maxWidth: '1140px',
    margin: '0 auto',
    height: '100%'
  },
  strike: {
    margin: 0,
    '&::after': {
      height: '10px',
      top: '30%'
    }
  },
  headerContainer: {
    backgroundColor: 'rgb(255, 255, 255)',
    height: '80px',
    // margin: '0 auto',
    borderBottom: '1px solid rgb(216, 216, 216)',
    // position: "-webkit-sticky",
    position: 'sticky',
    top: '0',
    zIndex: '999'
  },
  header: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  brand: {
    width: '20%'
  },
  brandImg: {
    cursor: 'pointer',
    width: '100%'
  },
  menuList: {
    display: 'flex'
  },
  menuItem: {
    padding: '0 10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center'
    }
  },
  actionSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mobileMenuList: {
    display: 'none'
  },
  hamMenu: {
    display: 'none'
  },
  mobileMenuItem: {},
  mobileMenuMenuItem: {},
  youtube: {
    color: '#ABABAB',
    fontSize: '28px!important',
    '& :hover': {
      color: 'red'
    }
  },
  whatsapp: {
    fontSize: '24px!important',
    color: '#ABABAB',
    '& :hover': {
      color: 'green'
    }
  },
  falseLink: {},
  contactNo: {
    fontSize: '13px'
  },
  mAuto: {
    margin: '0 auto'
  },
  '@media screen and (max-width: 990px)': {
    // "@media screen and (max-width: 760px)": {
    headerContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    header: {},
    brand: {
      width: '50%'
    },
    menuList: {
      display: 'none'
    },
    actionSection: {
      display: 'none'
    },
    mobileMenuList: {
      display: 'block'
    },
    hamMenu: {
      display: 'block'
    },
    mobileMenuItem: {
      flexDirection: 'column'
    },
    mobileMenuMenuItem: {
      background: '#F5F5F5!important',
      width: '90%!important',
      justifyContent: 'center!important',
      borderRadius: '10px!important',
      margin: '0 auto!important'
    },
    falseLink: {
      color: '#616161',
      padding: '10px 20px',
      display: 'flex',
      position: 'relative',
      textAlign: 'left',
      alignItems: 'center',
      fontSize: '14px!important',
      // justifyContent: "flex-start",
      textDecoration: 'none'
    }
  }
});

function Header({
  allCookies,
  applyNowOpen,
  handleSetApplyNowOpen,
  showMenu = true,
  ...props
}: {
  allCookies: any;
  applyNowOpen?: boolean;
  handleSetApplyNowOpen?: any;
  showMenu?: boolean;
}) {
  const classes = useStyles();

  const [menuDrawerOpen, setMenuDrawerOpen] = useState(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(null);
  const [selectedMoreMenu, setSelectedMoreMenu] = useState(null);

  const pages = [
    // {
    //   title: 'Pricing',
    //   anchor: true,
    //   link: '/#pricing',
    //   more: null
    // },
    // {
    //   title: 'Streams',
    //   anchor: false,
    //   // link: "/curriculum/",
    //   // more: [],
    //   more: [
    //     {
    //       title: 'Computational Thinking',
    //       anchor: false,
    //       link: '/coding/'
    //     },
    //     {
    //       title: 'Scientific Thinking',
    //       anchor: false,
    //       link: '/science'
    //     }
    //   ]
    // },
    {
      // title: (
      //     <span>
      //         <span className={clsx("strike-primary", classes.strike)}>
      //             Teachers</span> Coaches
      //     </span>
      // ),
      title: 'Our Coaches',
      link: '/teachers/',
      anchor: false,
      more: null
    },
    {
      title: 'Press & Media',
      link: '/press/',
      anchor: false
    }
    // {
    //     title: "More",
    //     more: [
    //         {
    //             title: "Our Story",
    //             link: "/"
    //         },
    //         {
    //             title: "Our Philosophy",
    //             link: "/"
    //         },
    //         {
    //             title: "Press & Media",
    //             link: "/"
    //         },
    //         {
    //             title: "Work At StayQ",
    //             link: "/"
    //         },
    //     ]
    // },
  ];
  // const pages = []

  const [queryParam, setQueryParam] = useState('');

  const router = useRouter();

  const showApplyNow = !router.pathname.includes('landing');

  useEffect(() => {
    const trackingUrlParams = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_term',
      'utm_content',
      'gclid',
      'referral'
    ];
    let queryParams = '';

    trackingUrlParams.reduce<{ [key: string]: string }>((obj, key) => {
      const val = allCookies[key];
      if (val) {
        queryParams += `${key}=${val}&`;
      }
      return obj;
    }, {});
    if (queryParams) {
      setQueryParam(`?${queryParams}`);
    }
  }, []);

  const handleMoreClose = () => {
    setMoreMenuOpen(null);
    setSelectedMoreMenu(null);
  };

  const handleMoreMenuOpen = (e, title) => {
    setMoreMenuOpen(e.currentTarget);
    setSelectedMoreMenu(title);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerMaxWidth}>
          <div className={classes.header}>
            {showMenu ? (
              <div
                className={classes.hamMenu}
                onClick={(e) => setMenuDrawerOpen(e.currentTarget)}
              >
                <MenuIcon />
              </div>
            ) : null}
            <div
              className={clsx(classes.brand, !showMenu ? classes.mAuto : '')}
            >
              <a
                href={`https://stayqrious.com${queryParam}`}
                className="flex-center"
                onClick={() =>
                  handleLinkClick(
                    HOME_BUTTON_EVENT,
                    null,
                    `https://stayqrious.com${queryParam}`
                  )
                }
              >
                <img
                  src={config.LOGO}
                  className={`img ${classes.brandImg}`}
                  height={50}
                  width={250}
                  alt="StayQrious"
                />
              </a>
            </div>
            {showMenu ? (
              <>
                <div className={classes.menuList}>
                  {pages.map((item, index) => (
                    <div key={index} className={classes.menuItem}>
                      {((item.link && item.more) || item.more) &&
                      item.more.length > 0 ? (
                        <span
                          onMouseOver={(e) => {
                            if (item.more) {
                              handleMoreMenuOpen(e, item.title);
                            }
                          }}
                        >
                          {item.title}
                        </span>
                      ) : (
                        <Link href={item.link ? item.link : ''}>
                          {item.title}
                        </Link>
                      )}

                      {item.more &&
                        moreMenuOpen &&
                        selectedMoreMenu === item.title && (
                          <Menu
                            keepMounted
                            anchorEl={moreMenuOpen}
                            open={Boolean(moreMenuOpen)}
                            onClose={handleMoreClose}
                          >
                            {item.more.map((moreItem, index) => (
                              <Link key={index} href={moreItem.link}>
                                <MenuItem onClick={handleMoreClose}>
                                  {moreItem.title}
                                </MenuItem>
                              </Link>
                            ))}
                          </Menu>
                        )}
                    </div>
                  ))}
                </div>

                <div className={classes.actionSection}>
                  {handleSetApplyNowOpen && showApplyNow && (
                    <div className={classes.menuItem}>
                      <Button
                        id="apply-now-navbar"
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleSetApplyNowOpen(e)}
                      >
                        Apply Now
                      </Button>
                    </div>
                  )}
                  <div className={classes.menuItem}>
                    {/* <YouTubeGrey /> */}
                    <a
                      id="youtube-navbar-link"
                      href={config.YOUTUBE_LINK}
                      onClick={(e) =>
                        handleLinkClick(
                          YOUTUBE_BUTTON_EVENT,
                          e,
                          config.YOUTUBE_LINK
                        )
                      }
                    >
                      <YouTube className={clsx('mui-icon', classes.youtube)} />
                    </a>
                  </div>
                  <div className={classes.menuItem}>
                    <a
                      id="whatsapp-navbar"
                      href={config.WHATSAPP_LINK}
                      onClick={(e) =>
                        handleLinkClick(
                          WHATSAPP_LINK_EVENT,
                          e,
                          config.WHATSAPP_LINK
                        )
                      }
                    >
                      <WhatsApp
                        className={clsx('mui-icon', classes.whatsapp)}
                      />
                    </a>
                    {/* <WhatsAppGrey /> */}
                  </div>
                </div>

                <div className={classes.mobileMenuList}>
                  <div className={styles.contact}>
                    <a
                      id="whatsapp-link-navbar"
                      href={config.WHATSAPP_LINK}
                      className="text-decoration-none flex-center"
                      onClick={(e) =>
                        handleLinkClick(
                          WHATSAPP_LINK_EVENT,
                          e,
                          config.WHATSAPP_LINK
                        )
                      }
                    >
                      <WhatsAppIcon />
                      {/* <div className={classes.contactNo}>
                        {config.WHATSAPP_NUMBER}
                      </div> */}
                    </a>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {menuDrawerOpen && (
          <Menu
            keepMounted
            anchorEl={menuDrawerOpen}
            open={Boolean(menuDrawerOpen)}
            onClose={() => setMenuDrawerOpen(null)}
          >
            {pages.map((item, index) => (
              <>
                {!item.link ? (
                  <Typography className={classes.falseLink}>
                    {item.title}
                  </Typography>
                ) : (
                  <MenuItem key={index} className={classes.mobileMenuItem}>
                    {item.link ? (
                      <span className="text-decoration-none">
                        <Link href={item.link}>{item.title}</Link>
                      </span>
                    ) : (
                      item.title
                    )}
                    {/* {
                                                    item.more && item.more.map((moreItem, index) => (
                                                        <MenuItem
                                                            className={clsx(classes.mobileMenuMenuItem, "text-decoration-none")}
                                                            key={index}
                                                        >
                                                            <Link
                                                                href={moreItem.link}
                                                            >
                                                                {moreItem.title}
                                                            </Link>
                                                        </MenuItem>
                                                    ))
                                                } */}
                  </MenuItem>
                )}

                {item.more &&
                  item.more.length > 0 &&
                  item.more.map((moreItem, index) => (
                    <MenuItem
                      className={clsx(
                        classes.mobileMenuMenuItem,
                        'text-decoration-none'
                      )}
                      key={index}
                      onClick={() => setMenuDrawerOpen(null)}
                    >
                      <Link href={moreItem.link}>{moreItem.title}</Link>
                    </MenuItem>
                  ))}
              </>
            ))}
          </Menu>
        )}
      </div>
    </>
  );
}

export default withCookies(Header);
